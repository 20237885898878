// eslint-disable-line
import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import  { Balance }  from './balance';
import MorList  from './MorList';
import Mor  from './Mor';
import Atc  from './Atc';
import Import from '../Import';
import { MorNames, AtsNames } from '../../../instruments/utils';

function Company(props) {
    const CustomComponents = {
        balance:       Balance,
        balance_phone: Balance,
        mor_ids:       MorList,
        mor_type:      Mor,
        atc_type:      Atc,
    };
    const listSelect = {
        mor_type: Object.keys(MorNames).map((i)=>{
            return { value: i, label: MorNames[ i ] };
        }),
        atc_type: Object.keys(AtsNames).map((i)=>{
            return { value: i, label: AtsNames[ i ] };
        }),
    };

    return (
        <div className = 'account'>
            <div style = {{ margin: '20px' }}>

                <Import
                    { ...props }
                    apiUrl =  { `/import_mor` }
                    name = 'Import mor'
                    type = { 'phone' }
                />
            </div>
            <ListConstructor
                { ...props }
                isShowAddBtn
                isShowDuplicate
                sortOrderDefault
                CustomComponents = { CustomComponents }
                fields = { listing.list }
                limit = { 50 }
                listSelect = { listSelect }
                sortDefault = 'name'
                type = { 'company' }
                urlApi = { '/company' }
            />

        </div>
    );
}

export default Company;
