const listing = {};

listing.list = {
    calldate: {
        type:   'date',
        title:  'Time',
        width:  '5',
        filter: 'DateField',
        sort:   true,

    },
    src: {
        type:  'text',
        title: 'Called From',
        width: '5',
    },
    dst: {
        type:  'text',
        title: ' Called To',
        width: '5',
    },
    duration: {
        type:  'text',
        title: 'Duration',
        width: '5',
        sort:  true,

    },
    disposition: {
        type:   'text',
        title:  'Status',
        width:  '5',
        filter: 'Select',

    },
    hangupcause: {
        type:   'text',
        title:  'Hangup Cause',
        width:  '2',
        filter: 'SearchV2',

    },
    server_id: {
        type:   'text',
        title:  'Server',
        width:  '3',
        filter: 'SearchV2',

    },
    provider_name: {
        type:   'text',
        title:  'Provider name',
        width:  '5',
        filter: 'SearchV2',
        roles:  [ 'admin', 'mnanager' ],
        sort:   true,


    },
    provider_rate: {
        type:  'text',
        title: 'Provider rate',
        width: '2',
        roles: [ 'admin', 'mnanager' ],
        sort:  true,


    },
    provider_price: {
        type:  'text',
        title: 'Provider price',
        width: '2',
        roles: [ 'admin', 'mnanager' ],
        sort:  true,


    },
    user_id: {
        type:       'text',
        title:      'Company name',
        width:      '5',
        filter:     'SearchV2',
        typeSearch: 'company_id',
        sort:       true,


    },
    user_rate: {
        type:  'text',
        title: 'User rate',
        width: '2',
        roles: [ 'admin', 'mnanager' ],
        sort:  true,


    },
    user_price: {
        type:  'text',
        title: 'company price',
        width: '2',
        sort:  true,

    },
    profit: {
        type:  'text',
        title: 'Profit',
        width: '2',
        roles: [ 'admin', 'mnanager' ],
        sort:  true,

    },

};

export { listing };
