// eslint-disable-line
import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { SelectedStatusAll } from './selectedStatusAll';
import { Balance } from './balance';

import { Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { tabsStyles, tabStyles } from '../ListConstructor/style';

function Postman(props) {
    const { type } = props;

    const CustomComponents = {
        selectedStatusAll: SelectedStatusAll,
        balance:           Balance,

    };
    const filter = {

        from: moment().date(1)
            .hour(0)
            .minute(0)
            .second(0),
    };
    console.log(type);

    return (
        <div className = 'account'>
            <Tabs
                aria-label = 'nav tabs'
                sx = { tabsStyles }
                value = { type }>
                <Tab
                    component = { Link }
                    key = { 1 }
                    label = { 'Список' }
                    sx = { tabStyles }
                    to = { '/admin/postman' }
                    value = { 'list' }
                />
                <Tab
                    component = { Link }
                    key = { 2 }
                    label = { 'Аналітика' }
                    sx = { tabStyles }
                    to = { '/admin/postman_analit' }
                    value = { 'analit' }


                />
            </Tabs>
            <ListConstructor
                { ...props }
                isShowAddBtn
                CustomComponents = { CustomComponents }
                fields = { type === 'list' ? listing.list : listing.list_analit  }
                filterOutside = { type === 'analit'  ? filter : {} }
                isDefaultFilter = { type === 'analit' }
                limit = { 200 }
                sortDefault = { type === 'list' ? 'name' : 'createdAt' }
                sortOrderDefault = { type === 'list' }
                type = { type === 'list' ? 'postman' : 'postman_analit' }
                urlApi = { type === 'list' ? '/postman' : '/postman_analit' }

            />

        </div>
    );
}

export default Postman;
