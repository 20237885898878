/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import   Phone   from '../Phone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Chip, Box } from '@mui/material';
import { api } from '../../../Api';
import { MultiSearch } from '../FormConstructor/multiSearch';
import { confirmAlert } from 'react-confirm-alert';
import '../../../theme/styles/confirmAllert.css';


const selectState = (state) => ({
    userData: state.swapi.userData,
});

export const Phones = (props) => {
    const state = useSelector(selectState);

    const  userData  = state.userData;

    const { fieldKey, item, setItem, isSingle } = props;
    const [ open, setOpen ] = useState(false);
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ isList, setIslist ] = useState(false);
    const [ isChange, setIsChange ] = useState(false);

    useEffect(() => {
        if (open === false) {
            setSelectedItems(item.phones);
        }
    }, [ item.phones, open ]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setIsChange(false);
        if (isChange) {
            handleSave();
        } else {
            setOpen(false);
        }
    };
    const onChange = (name, value) => {
        setSelectedItems(value);
        const newItem = { ...item };
        newItem[ fieldKey ] = value;
        setIsChange(true);
    };
    console.log(item);

    return (
        <div style = {{ padding: isSingle ? '20px' : 0 }}>
            { userData.role !== 'admin' ? (
                <h3>Підключенні номери</h3>
            ) : null}
            <Box sx = {{ marginBottom: '20px' }}>
                {item.phones && item.phones.length > 0
                    && item.phones.map((phoneObj, index) => (
                        <Chip
                            key = { index }
                            label = { phoneObj.label }
                            sx = {{ marginRight: '5px', marginBottom: '5px' }}
                        />
                    ))
                }
                {item.phones_reserved && item.phones_reserved.length > 0 ? (
                    <h3 style = {{ margin: '10px' }}>
                        Резерв
                    </h3>
                ) : null}

                {item.phones_reserved && item.phones_reserved.length > 0
                    && item.phones_reserved.map((phoneObj, index) => (
                        <Chip
                            key = { index }
                            label = { phoneObj.label }
                            sx = {{ marginRight: '5px', marginBottom: '5px' }}
                        />
                    ))
                }
            </Box>

        </div>
    );
};
