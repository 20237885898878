// eslint-disable-line
import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { InputMask } from './inputMask';
// import { api } from '../../../Api';
import ImportCsv from '../Import/importCsv';
function Phone(props) {
    const CustomComponents = {
        phone: InputMask,
    };
    // const selectedAllStaus = async (status, filter) => {
    //     await api.postData({ status: status, filter: filter }, '/phone/selectedAllStatus');
    // };

    return (
        <div className = 'account'>
            <div style = {{ margin: '20px', display: 'flex', justifyContent: 'space-between' }}>

                <ImportCsv
                    { ...props }
                    apiUrl =  { `/import_phone` }
                    name = 'Підключити номера компаній'
                    type = { 'phone' }
                />
                <ImportCsv
                    { ...props }
                    apiUrl =  { `/import_phone_postman` }
                    name = 'Завантажити номера постачальників'
                    type = { 'phone' }
                />
                <ImportCsv
                    { ...props }
                    apiUrl =  { `/import_disconect_phone` }
                    name = 'Відключити номера'
                    type = { 'import_disconect_phone' }
                />
            </div>
            <ListConstructor
                { ...props }
                isShowAddBtn
                CustomComponents = { CustomComponents }
                fields = { listing.list }
                limit = { 200 }
                //selectedAllStaus =  { selectedAllStaus }
                //selectedAllTxt = { !props.isCompanyModule ? 'всім статус' : '' }
                sortDefault = 'createdAt'
                sortOrderDefault = { false }
                type = { 'phone' }
                urlApi = { '/phone' }
            />

        </div>
    );
}

export default Phone;
