const inputWrapStyles =  { position: 'relative', width: '100%' };
const inputFlexStyles =  { position: 'relative', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' };


const inputStyles = {
    width:           '100%',
    backgroundColor: '#fff',
};
const searchListStyles = {
    position:        'absolute',
    zIndex:          10,
    top:             '100%',
    left:            0,
    maxHeight:       '300px',
    overflowY:       'auto',
    backgroundColor: '#ffffff',
    color:           '#000000',
    padding:         '8px',
    boxShadow:       '0px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius:    4,

};

const closeButtonStyles = {
    position: 'absolute',
    top:      4,
    right:    -2,
};

const listItemStyles = {
    cursor:  'pointer',
    padding: '4px 0',
    width:   '100%',
};


const listItemHoverStyles = {
    backgroundColor: '#f5f5f5',
};

const modalStyle = {
    position:        'absolute',
    top:             '50%',
    left:            '50%',
    transform:       'translate(-50%, -50%)',
    width:           '80%',
    maxWidth:        '900px',
    backgroundColor: '#fff',
    padding:         '20px',
    maxHeight:       '90%',
    overflow:        'scroll',
};
const modalSmallStyle = {
    position:        'absolute',
    top:             '20%',
    left:            '50%',
    transform:       'translate(-50%, -50%)',
    width:           '20%',
    maxWidth:        '700px',
    backgroundColor: '#fff',
    padding:         '20px',
    textAlign:       'center',
};
const formSingle = {
    width:           '100%',
    margin:          '0 auto',
    display:         'flex',
    flexWrap:        'wrap',
    backgroundColor: '#f9f9f9',
    justifyContent:  'space-between',
    alignItems:      'flex-start',
    padding:         '20px calc((100vw - 1600px) /2)',


    '@media only screen and (max-width:1400px)': {
        padding: '20px calc((100vw - 1100px) /2)',
    },

};
const formSingleCustom = {
    display:         'flex',
    flexWrap:        'wrap',
    justifyContent:  'space-between',
    alignItems:      'center',
    backgroundColor: '#fff',
    padding:         '15px',

};
const formRow = {
    display:        'flex',
    justifyContent: 'space-between',
    margin:         '10px 0',
    flexDirection:  'column',
    alignAtems:     'flex-start',
};
const formRowCustom = {
    justifyContent: 'space-between',
    margin:         '10px 0',
};
const formRowFlex = {
    display:        'flex',
    justifyContent: 'space-between',
    margin:         '20px 0',
    alignItems:     'flex-start',
    padding:        '10px',
    borderRadius:   '10px',
    boxShadow:      '0px 5px 15px -10px',
};
const formCustom = {
    background:   '#fff',
    boxShadow:    '0px 5px 15px -10px',
    borderRadius: '10px',
    margin:       '10px 0 30px',
};
const changeBtn = {
    position: 'absolute',
    left:     '-20px',
    top:      '0',
    width:    '20px',
    height:   '20px',
    zIndex:   '100',
    color:    'green',
    cursor:   'pointer',
};
const onlyRead = {
    background:   "#fff",
    padding:      '10px',
    borderRadius: '4px',
};
const lengthText = {
    position: "absolute",
    top:      '7px',
    left:     '-23px',
    color:    'red',
};
const imageUpload = {
    '&input': {
        display: 'none',
    },
};
const hoverAnimate = `
    animation: hoverAnimation 1s linear;
    transform:scale(1);
  @keyframes hoverAnimation {
    0% {
      transform: scale(1);
    }
    25% {
        transform: scale(1.1);
      }
    50% {
        transform: scale(1.2);
      }
    75% {
    transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;
const hoverAnimateLong = `
    animation: hoverAnimation 2s linear;
    transform:scale(1);
  @keyframes hoverAnimation {
    0% {
      transform: scale(1);
    }
    24% {
        transform: scale(1);
      }
    25% {
        transform: scale(1.1);
      }
    50% {
        transform: scale(1.2);
      }
    75% {
    transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;
const stylePencil = {
    color:    '#1976d2',
    fontSize: '16px',
    cursor:   'pointer',
};

export  {
    inputWrapStyles,
    inputStyles,
    searchListStyles,
    closeButtonStyles,
    listItemStyles,
    listItemHoverStyles,
    modalStyle,
    modalSmallStyle,
    formSingle,
    formRow,
    imageUpload,
    formRowCustom,
    formSingleCustom,
    hoverAnimate,
    hoverAnimateLong,
    formCustom,
    changeBtn,
    onlyRead,
    formRowFlex,
    lengthText,
    stylePencil,
    inputFlexStyles,
};
