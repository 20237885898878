/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { api } from '../../../Api';
import { confirmAlert } from 'react-confirm-alert';
import '../../../theme/styles/confirmAllert.css';
import '../../../theme/styles/flexTable.css';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const ModalPhones = (props) => {
    const { notPayList, payList } = props;
    const [ notPayMonthList, setNotPayMonthList ] = useState(notPayList);
    const [ payMonthList, setPayMonthList ] = useState(payList);
    const [ openListPostman, setOpenListPostman ] = useState(false);
    useEffect(() => {
        if (notPayList.length > 0 || payList.length > 0) {
            setOpenListPostman(true);
            setNotPayMonthList(notPayList);
            setPayMonthList(payList);
        }
    }, [ notPayList, payList ]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [ removed ] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    // Функція для переміщення елемента між двома списками
    const move = (source, destination, sourceIndex, destinationIndex) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [ removed ] = sourceClone.splice(sourceIndex, 1);

        destClone.splice(destinationIndex, 0, removed);

        const result = {};
        result.notPayMonthList = source === notPayMonthList ? sourceClone : destClone;
        result.payMonthList = destination === payMonthList ? destClone : sourceClone;

        return result;
    };

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;

        // Якщо немає місця призначення, нічого не робимо
        if (!destination) {
            return;
        }

        // Якщо елемент переміщено до того ж списку
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                source.droppableId === "notPayMonthList" ? notPayMonthList : payMonthList,
                source.index,
                destination.index,
            );

            if (source.droppableId === "notPayMonthList") {
                setNotPayMonthList(items);
            } else {
                setPayMonthList(items);
            }
        } else {
            // Якщо елемент переміщено між списками
            const result = move(
                source.droppableId === "notPayMonthList" ? notPayMonthList : payMonthList,
                destination.droppableId === "payMonthList" ? payMonthList : notPayMonthList,
                source.index,
                destination.index,
            );

            setNotPayMonthList(result.notPayMonthList);
            setPayMonthList(result.payMonthList);
        }
    };

    // Обробник збереження
    const handleSavePostman = () => {
        confirmAlert({
            title:            'Підтвердження змін',
            message:          '',
            overlayClassName: 'confirmAlertOverlay',
            buttons:          [
                {
                    label:   'Yes',
                    onClick: async () => {
                        const result = await api.postData({
                            notPayMonthList,
                            payMonthList,
                        }, `/postman_disable_number`);

                        console.log('result', result);
                    },
                    className: 'confirm',
                },
                {
                    label:     'No',
                    className: 'cancel',
                },
            ],
        });
        setOpenListPostman(false);
    };

    return (
        <Dialog
            maxWidth = 'lg'
            open = { openListPostman }>
            <div style = {{ width: '1200px', display: 'flex' }}>
                <DragDropContext onDragEnd = { handleOnDragEnd }>
                    <DialogContent sx = {{ width: '600px', height: '600px' }}>
                        <DialogTitle>
                            Список номерів на відключення ЗАРАЗ
                        </DialogTitle>
                        <Droppable droppableId = 'notPayMonthList'>
                            {(provided) => (
                                <div
                                    className = 'ft_wrap'
                                    { ...provided.droppableProps }
                                    ref = { provided.innerRef }>
                                    <div className = 'ft_header'>
                                        <div className = 'ft_header_item'>
                                            { `Постачальник` }
                                        </div>
                                        <div className = 'ft_header_item'>
                                            { `Номер` }
                                        </div>
                                        <div className = 'ft_header_item'>
                                            { 'Дата активації' }
                                        </div>
                                        <div className = 'ft_header_item'>
                                            { 'Дата наст. платежу' }
                                        </div>
                                    </div>
                                    {notPayMonthList.map((el, index) => (
                                        <Draggable
                                            draggableId = { el._id }
                                            index = { index }
                                            key = { el._id }>
                                            {(provided) => (
                                                <div
                                                    className = 'ft_row'
                                                    ref = { provided.innerRef }
                                                    { ...provided.draggableProps }
                                                    { ...provided.dragHandleProps }
                                                    style = {{
                                                        ...provided.draggableProps.style,
                                                    }}>
                                                    <div className = 'ft_row_item ft_left'>
                                                        { `${el.postman}` }
                                                    </div>
                                                    <div className = 'ft_row_item'>
                                                        { `${el.phone}` }
                                                    </div>
                                                    <div className = 'ft_row_item'>
                                                        { el.date_active ? moment(el.date_active).format('DD.MM.YYYY') : '' }
                                                    </div>
                                                    <div className = 'ft_row_item'>
                                                        { el.next_pay ? moment(el.next_pay).format('DD.MM.YYYY') : '' }
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DialogContent>
                    <DialogContent sx = {{ width: '600px', height: '600px' }}>
                        <DialogTitle>
                            Список номерів на відключення ПІСЛЯ ОПЛАТИ
                        </DialogTitle>
                        <Droppable droppableId = 'payMonthList'>
                            {(provided) => (
                                <div
                                    className = 'ft_wrap'
                                    { ...provided.droppableProps }
                                    ref = { provided.innerRef }>
                                    <div className = 'ft_header'>
                                        <div className = 'ft_header_item'>
                                            { `Постачальник` }
                                        </div>
                                        <div className = 'ft_header_item'>
                                            { `Номер` }
                                        </div>
                                        <div className = 'ft_header_item'>
                                            { 'Дата активації' }
                                        </div>
                                        <div className = 'ft_header_item'>
                                            { 'Дата наст. платежу' }
                                        </div>
                                    </div>
                                    {payMonthList.map((el, index) => (
                                        <Draggable
                                            draggableId = { el._id }
                                            index = { index }
                                            key = { el._id }>
                                            {(provided) => (
                                                <div
                                                    className = 'ft_row'
                                                    ref = { provided.innerRef }
                                                    { ...provided.draggableProps }
                                                    { ...provided.dragHandleProps }
                                                    style = {{
                                                        ...provided.draggableProps.style,
                                                    }}>
                                                    <div className = 'ft_row_item ft_left'>
                                                        { `${el.postman.name}` }
                                                    </div>
                                                    <div className = 'ft_row_item'>
                                                        { `${el.phone}` }
                                                    </div>
                                                    <div className = 'ft_row_item'>
                                                        { el.date_active ? moment(el.date_active).format('DD.MM.YYYY') : '' }
                                                    </div>
                                                    <div className = 'ft_row_item'>
                                                        { el.next_pay ? moment(el.next_pay).format('DD.MM.YYYY') : '' }
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DialogContent>
                </DragDropContext>
            </div>
            <DialogActions>
                <Button
                    color = 'primary'
                    onClick = { ()=>setOpenListPostman(false) }>
                    Відмінити
                </Button>
                <Button
                    color = 'primary'
                    onClick = { handleSavePostman }>
                    Зберегти
                </Button>
            </DialogActions>
        </Dialog>
    );
};
