import React from 'react';
import { Button } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // імпорт бібліотеки
import { api } from '../../../Api';

export const BtnService = (props) => {
    const { data, placeholder, item, nameField, setItem } = props;

    const sendData = async () => {
        const [ index ] = nameField.split('|');
        const params = {
            index:      Number(index),
            item,
            company_id: data._id,
        };
        const result = await api.postData(params, '/writeOffService/');
        if (result?.data?._id && setItem) {
            setItem((prev) => {
                return {
                    ...prev,
                    balance_history: result.data.balance_history,
                    balance:         result.data.balance,
                };
            });
        }
    };

    const handleConfirm = () => {
        confirmAlert({
            title:   'Підтвердження дії',
            message: 'Ви впевнені, що хочете виконати цю дію?',
            buttons: [
                {
                    label:     'Yes',
                    onClick:   () => sendData(),
                    className: 'confirm',

                },
                {
                    label:     'No',
                    onClick:   () => console.log('Дію скасовано'),
                    className: 'cancel',

                },
            ],
        });
    };

    return (
        <Button
            variant = { 'contained' }
            onClick = { () => handleConfirm() }>
            {placeholder}
        </Button>
    );
};
