import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import Postman from '../Postman';
import Single from '../Postman/single';
export const PostmanRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <Postman
                        { ...props }
                        type = 'list'

                    />
                }
                path = { book.admin.postman }
            />
            <Route
                exact
                element = {
                    <Single
                        { ...props }
                    />
                }
                path = { book.admin.postmanSingle }
            />
            <Route
                exact
                element = {
                    <Postman
                        { ...props }
                        type = 'analit'
                    />
                }
                path = { book.admin.postman_analit }
            />
        </Routes>
    );
};
