/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Chip, Box } from '@mui/material';
import { api } from '../../../Api';
import { MultiSearch } from '../FormConstructor/multiSearch';
import { confirmAlert } from 'react-confirm-alert';
import '../../../theme/styles/confirmAllert.css';
import '../../../theme/styles/flexTable.css';
import { ModalPhones } from './modalPhones';

const selectState = (state) => ({
    userData: state.swapi.userData,
});

export const Phones = (props) => {
    const state = useSelector(selectState);

    const  userData  = state.userData;

    const { fieldKey, item, setItem, isSingle } = props;
    const [ open, setOpen ] = useState(false);
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ selectedItemsReserved, setSelectedItemsReserved ] = useState([]);

    const [ isChange, setIsChange ] = useState(false);
    const [ notPayMonthList, setNotPayMonthList ] = useState([]);
    const [ payMonthList, setPayMonthList ] = useState([]);

    useEffect(() => {
        if (open === false) {
            setSelectedItems(item.phones);
            setSelectedItemsReserved(item.phones_reserved);
        }
    }, [ item.phones, item.phones_reserved, open ]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleSave = () => {
        confirmAlert({
            title:            'Підтвердження змін',
            message:          '',
            overlayClassName: 'confirmAlertOverlay',
            buttons:          [
                {
                    label:   'Yes',
                    onClick: async () => {
                        setNotPayMonthList([]);
                        setPayMonthList([]);
                        const result = await api.postData({
                            field:            fieldKey,
                            selectedItems:    [ ...selectedItems, ...selectedItemsReserved ],
                            id:               item._id,
                            is_pay_connect:   true,
                            is_pay_subscribe: true,
                        }, `/changePhones/${item._id}`);
                        if (result?.data && setItem) {
                            setItem(result.data);
                        }

                        if (result?.postmans?.not_pay_month
                            && result.postmans.not_pay_month.length > 0) {
                            setNotPayMonthList(result.postmans.not_pay_month);
                        }
                        if (result?.postmans?.pay_month
                            && result.postmans.pay_month.length > 0) {
                            setPayMonthList(result.postmans.pay_month);
                        }
                    },
                    className: 'confirm',
                },
                {
                    label:     'No',
                    className: 'cancel',
                },
            ],
        });
        setOpen(false);
    };
    const handleClose = () => {
        setIsChange(false);
        if (isChange) {
            handleSave();
        } else {
            setOpen(false);
        }
    };
    const onChange = (name, value) => {
        setSelectedItems(value);
        const newItem = { ...item };
        newItem[ fieldKey ] = value;
        setIsChange(true);
    };

    return (
        <div style = {{ padding: isSingle ? '20px' : 0 }}>
            { userData.role !== 'admin' ? (
                <h3>Підключенні номери</h3>
            ) : null}
            <Box sx = {{ marginBottom: '20px' }}>
                {item.phones && item.phones.length > 0
                    && item.phones.map((phoneObj, index) => (
                        <Chip
                            key = { index }
                            label = { phoneObj.label }
                            sx = {{ marginRight: '5px', marginBottom: '5px' }}
                        />
                    ))
                }
                {item.phones_reserved && item.phones_reserved.length > 0 ? (
                    <h3 style = {{ margin: '10px' }}>
                        Резерв
                    </h3>
                ) : null}
                {item.phones_reserved && item.phones_reserved.length > 0
                    && item.phones_reserved.map((phoneObj, index) => (
                        <Chip
                            key = { index }
                            label = { phoneObj.label }
                            sx = {{ marginRight: '5px', marginBottom: '5px' }}
                        />
                    ))
                }
            </Box>
            { userData.role === 'admin' ? (
                <Button
                    color = 'primary'
                    variant = 'contained'
                    onClick = { handleClickOpen }>
                    Управління номерами
                </Button>
            ) : null}


            <Dialog
                maxWidth = { 'lg' }
                open = { open }
                width = { 1200 }
                onClose = { handleClose }>
                <DialogTitle>Додати номер телефону</DialogTitle>
                <DialogContent sx = {{ width: '1200px', height: '600px'  }} >
                    <MultiSearch
                        nameField = 'phones'
                        placeholder = 'Виберіть телефон'
                        setFieldValue = { onChange }
                        type = 'phonesFree'  // передайте дані для пошуку
                        valueField = { selectedItems }
                    />
                    {/* {isList ? (
                        <Phone
                            isCompanyModule
                            limit = { 5 }
                        />
                    ) : (
                        <Button
                            color = 'primary'
                            variant = 'contained'
                            onClick = { ()=>setIslist(true) }>
                            створити
                        </Button>
                    )  } */}

                </DialogContent>
                <DialogActions>
                    <Button
                        color = 'secondary'
                        onClick = { () => {
                            setOpen(false);
                            setIsChange(false);
                        } }>
                        Відмінити
                    </Button>

                    <Button
                        color = 'primary'
                        onClick = { () => handleSave() }>
                        Зберегти
                    </Button>
                </DialogActions>
            </Dialog>
            <ModalPhones
                notPayList = { notPayMonthList }
                payList = { payMonthList }
            />
        </div>
    );
};
